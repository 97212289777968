import styled from "styled-components"
import Title from "../../Title/Title";

const ListPalmaresWrapper = styled.div `

    position:relative;
    display:block;
    width:60%;

    @media (max-width:500px) {
        width:80%;
    }

    & > div > div {
        margin:30px 0;
        line-height:38px;
    }

    & > div > div > span {
        background:#ffed00;
        padding:10px 14px;
        font-weight:500;
        border-radius:12px;
        margin: 0 10px 0 0;
    }

`

const ListPalmares = () => {

    return (

    <ListPalmaresWrapper>

        <Title>Palmarès du club</Title>

        <div>
            <div><span>2019</span>Accession aux Play-Off d'Élite Féminine avec BMV & Montée en Nationale 2 Féminine</div>
            <div><span>2015</span>Montée en nationale 2 du secteur féminin</div>
            <div><span>2013</span>Création de BMV, Bordeaux Mérignac Volley, un groupement sportif entre les équipes féminines du
            SAM (Mérignac) et des JSA (Bordeaux) pour développer la pratique du sport de haut niveau</div>
            <div><span>2012</span>Montée en nationale 2 du secteur masculin (jusqu'en 2014)</div>
            <div><span>2010</span>Lancement du Baby Volley</div>
            <div><span>2008</span>Montée en nationale 3 du secteur féminin</div>
            <div><span>2007</span>Montée en nationale 3 du secteur masculin</div>
            <div><span>2004</span>Création de l'école de volley des JSA</div>
            <div><span>2001-2002</span>Création d'une nouvelle section</div>
            <div><span>2001</span>Mise en liquidation judiciaire du club Bordeaux Volley (évoluant alors en championnat professionnel)</div>
            <div><span>1984</span>1ère montée en nationale 1 et participation au 1er championnat avec l'élite en 1985</div>
            <div><span>1981</span>Inauguration du Centre socio-culturel et sportif qui deviendra la Maison de Quartier en 1983</div>
            <div><span>1978</span>1ère montée en nationale 2 du secteur masculin</div>
            <div><span>1976</span>1ère montée en nationale 3 du secteur masculin</div>
            <div><span>1963</span>Création de la section Volley Ball des JSA</div>
        </div>

    </ListPalmaresWrapper>

    );

}

export default ListPalmares;