import React, { useEffect } from "react";
import NextGame from "../../components/Interface/Scores/NextGame/NextGame";
import Classement from "../../components/Interface/Scores/Classement/Classement"
import ShopAdvertisment from "../../components/Interface/ShopAdvertisment/ShopAdvertisment";
import WeekGames from "../../components/Interface/Scores/WeekGames/WeekGames";
import Sponsors from "../../components/Interface/Sponsors/Sponsors";
import LastPosts from "../../components/Interface/Instagram/LastPosts";
import styled from "styled-components";

const HomeWrapper = styled.div `
    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    transform:translateY(-120px);
`

const Home = () => {

    useEffect(() => {
        const titlepage = document.getElementById("titlepage");
        titlepage.innerText = "";
    });

    return (

    <HomeWrapper>
        <NextGame />
        <WeekGames />
        <ShopAdvertisment />
        <Classement />
        <Sponsors />
        
    </HomeWrapper>

    );
}

export default Home;