import styled from 'styled-components';

const NextGameWidget = styled.iframe ` 

    display:block;
    width:800px;
    overflow:auto;
    margin:auto;
    border-width:0px;
    border-radius:12px;

    @media (max-width:500px) {
        width:90%;
        margin:5%;;
    }


`

const NextGame = () => {

    return <NextGameWidget id="657ad87d21f98b08d9bb2db7" height="240" src="https://v1.scorenco.com/widget/657ad87d21f98b08d9bb2db7/"></NextGameWidget>

}

export default NextGame;