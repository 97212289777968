import React, { useEffect } from "react";
import styled from "styled-components";
import ListPalmares from "../../components/Interface/Club/ListPalmares/ListPalmares";
import InfoClub from "../../components/Interface/Club/InfoClub/InfoCLub";

const HistoryWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

`

const History = () => {

    useEffect(() => {
        const titlepage = document.getElementById("titlepage");
        titlepage.innerText = "Histoire et Palmarès des JSA";
        const titlehtml = document.querySelector("title");
        titlehtml.innerText = "JSA Volley Ball - Histoire et Palmares";
    });

    return (

    <HistoryWrapper>
        <InfoClub />
        <ListPalmares />
    </HistoryWrapper>

    );
    
}

export default History;