import Header from "./../components/Interface/Header/Header";
import Footer from "../components/Interface/Footer/Footer";
import Area from "../components/Interface/Area/Area";

const Interface = () => {

    return (

    <>
        <Header />
        <Area />
        <Footer />
    </>
    
    );

}

export default Interface;