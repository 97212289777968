import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableList from "../../components/Admin/TableList/TableList";
import Row from "../../components/Admin/TableList/Row/Row";
import TableHead from "../../components/Admin/TableList/TableHead/TableHead";
import TableBody from "../../components/Admin/TableList/TableBody/TableBody";
import { Link, useNavigate } from "react-router-dom"
import ErrorMessage from "../../components/Admin/ErrorMessage/ErrorMessage";
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import { API_URL, cookieValue } from "../../utils";

const StyledLink = styled(Link) `
    color:rgba(52, 152, 219,1.0);
    font-weight:bold;
    margin:0 50px;
    text-decoration:none;
`

const MessagesWrapper = styled.div `
    margin:0 50px;
`

const UserList = () => {

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchAPI = async () => {

            const callAPI = await fetch(API_URL + "/user/getall", {
                headers: {
                    'Authorization': `TK ${cookieValue('auth')}`,
                }
            });
            const dataAPI = await callAPI.json();
            if(dataAPI.users && dataAPI.users.length !== 0) setUsers(dataAPI.users);
    
        }

        fetchAPI();

    }, []);

    const handleUpdate = (id) => navigate('/adm/users/update/' + id);

    const handleDelete = async (id) => {

        /* eslint-disable no-restricted-globals */
        const del = confirm("Confirmez la suppression ?");

        if(del) {
            
            try {

                const callAPI = await fetch(API_URL + "/user/delete", {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `TK ${cookieValue('auth')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userid: id
                    })
                });

                if(callAPI.status === 404) throw new Error("L'utilisateur n'existe pas.");

                navigate('/adm/users/list');

            }
            catch(e) {
                setError(e.message);
            }

        }
        else setError("Non supprimé");

    }

    return (

    <>

        <PageTitle>Gestion des accès</PageTitle>
        <MessagesWrapper>
            { error ? <ErrorMessage>{error}</ErrorMessage> : null }
        </MessagesWrapper>
        <StyledLink to="/adm/users/create">Créer un utilisateur</StyledLink>
        <TableList>
            <TableHead values={["Nom", "Prénom", "Email"]} commands={true} />
            <TableBody>
                { users.map((user) => 
                    <Row key={user.id} values={[user.name, user.firstname, user.email]} onUpdate={() => {handleUpdate(user.id)}} onDelete={() => {handleDelete(user.id)}} />
                ) }
            </TableBody>
        </TableList>

    </>

    );
}

export default UserList;