import { useState } from "react";
import Form from "../Form/Form";
import TextInput from "../Form/TextInput/TextInput";
import ImageInput from "../Form/ImageInput/ImageInput";
import ButtonField from "../Form/ButtonField/ButtonField";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { API_URL, cookieValue } from "../../../utils";

const FormSponsor = (props) => {

    const navigate = useNavigate();

    // Form fields
    const [sponsorName, setSponsorName] = useState(props.sponsorName ? props.sponsorName : "");
    const [sponsorWebsite, setSponsorWebsite] = useState(props.sponsorWebsite ? props.sponsorWebsite : "");
    const [img, setImg] = useState(null);

    // Errors
    const [error, setError] = useState(null);

    /**
     * @param {*} e 
     */
    const handleChangeName = (e) => {

        if(!e.target.value || !e.target.value.length > 50) setError("Veuillez préciser un nom, et faisant moins de 50 caractères.");
        else setError(null);

        setSponsorName(e.target.value);
    }

    /**
     * @param {*} e 
     */
    const handleChangeWebsite = (e) => {

        if(!e.target.value || !e.target.value.length > 50) setError("Veuillez préciser un site web, et faisant moins de 50 caractères.");
        else setError(null);

        setSponsorWebsite(e.target.value);
    }

    /**
     * @param {*} e 
     */
    const handleChangeImg = (e) => {

        const selectedFile = e.target.files[0];

        const maxSize = 5 * 1024 * 1024;
        if (selectedFile && selectedFile.size > maxSize) setError('La taille du fichier dépasse la limite autorisée.');
        else setError(null);

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (selectedFile && !allowedTypes.includes(selectedFile.type)) setError('Type de fichier non autorisé. Veuillez sélectionner une image.');
        else setError(null);
        
        setImg(selectedFile);

    }

    /**
     * @param {*} e 
     */
    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const formData = new FormData();

            formData.append("name", sponsorName);
            formData.append("website", sponsorWebsite);
            formData.append("id", props.sponsorId ? props.sponsorId : 0);
            formData.append("img", img);

            const callAPI = await fetch(API_URL + "/sponsor/update", {
                method: 'POST',
                headers: {
                    'Authorization': `TK ${cookieValue('auth')}`,
                },
                body: formData
            });

            if(callAPI.status === 400) throw new Error("Informations incorrectes.");

            navigate("/adm/sponsors/list");

        }
        catch(e) {
            setError(e.message);
        }

    }

    return (

    <>

        <Form>

            <tr>
                <td></td>
                <td>
                    { error ? <ErrorMessage>{error}</ErrorMessage> : null }
                </td>
            </tr>

            <TextInput label="Nom du sponsor" value={sponsorName} onChange={handleChangeName} />
            <TextInput label="Site web du sponsor" value={sponsorWebsite} onChange={handleChangeWebsite} />
            <ImageInput label="Logo du sponsor" onChange={handleChangeImg} />
            <ButtonField onClick={ handleSubmit }>Enregistrer</ButtonField>
            
        </Form>

    </>

    )


}

export default FormSponsor;