import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableList from "../../components/Admin/TableList/TableList";
import Row from "../../components/Admin/TableList/Row/Row";
import TableHead from "../../components/Admin/TableList/TableHead/TableHead";
import TableBody from "../../components/Admin/TableList/TableBody/TableBody";
import { Link, useNavigate } from "react-router-dom"
import ErrorMessage from "../../components/Admin/ErrorMessage/ErrorMessage";
import { API_URL, cookieValue } from "../../utils";
import PageTitle from "../../components/Admin/PageTitle/PageTitle";

const StyledLink = styled(Link) `
    color:rgba(52, 152, 219,1.0);
    font-weight:bold;
    margin:0 50px;
    text-decoration:none;
`

const MessagesWrapper = styled.div `
    margin:0 50px;
`

const PlayerList = () => {

    const navigate = useNavigate();

    const [players, setPlayers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchAPI = async () => {
            const callAPI = await fetch(API_URL + "/player/getall", {

                headers: {
                    'Authorization': `TK ${cookieValue('auth')}`,
                }

            });
            const dataAPI = await callAPI.json();
            if(dataAPI.players && dataAPI.players.length !== 0) setPlayers(dataAPI.players.sort((a, b) => a.name.localeCompare(b.name)));
        }

        fetchAPI();

    }, []);

    const handleUpdate = (id) => navigate('/adm/players/update/' + id);

    const handleDelete = async (id) => {

        /* eslint-disable no-restricted-globals */
        const del = confirm("Confirmez la suppression ?");

        if(del) {
            
            try {

                const callAPI = await fetch(API_URL + "/player/delete", {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `TK ${cookieValue('auth')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        playerid: id
                    })
                });

                if(callAPI.status === 404) throw new Error("Le joueur n'existe pas.");

                navigate('/adm/players/list');

            }
            catch(e) {
                setError(e.message);
            }

        }
        else setError("Non supprimé");

    }

    return (

    <>

        <PageTitle>Gestion des joueurs</PageTitle>
        <MessagesWrapper>
            { error ? <ErrorMessage>{error}</ErrorMessage> : null }
        </MessagesWrapper>
        <StyledLink to="/adm/players/create">Créer un joueur</StyledLink>
        <TableList>
            <TableHead values={["Nom du joueur", "Prénom du joueur", "Poste", "Numéro de maillot"]} commands={true} />
            <TableBody>
                { players.map((player) => 
                    <Row key={player.id} values={[player.name, player.firstname, player.poste, player.nb]} onUpdate={() => {handleUpdate(player.id)}} onDelete={() => {handleDelete(player.id)}} />
                ) }
            </TableBody>
        </TableList>

    </>
    
    );
}

export default PlayerList;