import styled from "styled-components";

const StyledButton = styled.button `

    height:36px;
    width:300px;
    margin:15px 0 0 0;
    border:0;
    background:rgba(241, 196, 15,1.0);
    font-weight:bold;
    outline:0;
    border-radius:6px;
    cursor:pointer;
    transition:all .3s ease;

    &:hover {
        transition:all .3s ease;
        background:rgba(241, 196, 15,0.8)
    }

`

const Button = (props) => {

    return <StyledButton onClick={props.onClick ? props.onClick : null}>{props.children}</StyledButton>

}

export default Button;