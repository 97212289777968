import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Title from "../Title/Title";
import { API_URL } from '../../../utils';

const SponsorsWrapper = styled.div `

    display:flex;
    flex-direction:column;
    margin:50px 0 0 0;
    background:#ffed00;
    width:100%;
    padding:20px 0; 
    display:flex;
    justify-content:center;
    align-items:center;

    & > div {
	align-items:center;
        display:flex;
        flex-direction:row;
    }

    & > div > a > img {
        width:100px;
        margin:20px;
        transition:all .2s ease;
    }

    & > div > a > img:hover {
        transition:all .2s ease;
        transform:scale(1.05);
    }

`

const Sponsors = () => {

    const [sponsors, setSponsors] = useState([]);

    useState(() => {

        const fetchAPI = async () => {

            const callAPI = await fetch(API_URL + "/sponsor/getall");
            const dataAPI = await callAPI.json();

            setSponsors(dataAPI.sponsors);

        }

        fetchAPI();

    });

    return (

    <SponsorsWrapper>
        <Title centered={true}>Partenaires</Title>
        <div>
            { sponsors.map((sponsor) => <a href={sponsor.website} target="_blank" key={sponsor.id}><img src={sponsor.img ? API_URL + "/sponsor/getimage?sponsorid=" + sponsor.id : "https://placehold.co/600x400/png" }></img></a>) }
        </div>
    </SponsorsWrapper>

    );
    
}

export default Sponsors;
