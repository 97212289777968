import { useEffect, useState } from "react";
import Form from "../Form/Form";
import TextInput from "../Form/TextInput/TextInput";
import SelectInput from "../Form/SelectInput/SelectInput";
import ImageInput from "../Form/ImageInput/ImageInput";
import ButtonField from "../Form/ButtonField/ButtonField";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { API_URL, cookieValue } from "../../../utils";

const FormTeam = (props) => {

    const navigate = useNavigate();

    // Form fields
    const [teamCategories, setTeamCategories] = useState([]);
    const [teamName, setTeamName] = useState(props.teamName ? props.teamName : "");
    const [teamWidget, setTeamWidget] = useState(props.teamWidget ? props.teamWidget : "");
    const [teamClassement, setTeamClassement] = useState(props.teamClassement ? props.teamClassement : "");
    const [teamDates, setTeamDates] = useState(props.teamDates ? props.teamDates : "");
    const [teamCoachName, setTeamCoachName] = useState(props.teamCoachName ? props.teamCoachName : "");
    const [teamCoachPhone, setTeamCoachPhone] = useState(props.teamCoachPhone ? props.teamCoachPhone : "");
    const [teamCategoryId, setTeamCategoryId] = useState(props.teamCategoryId ? props.teamCategoryId : 1);
    const [img, setImg] = useState(null);

    // Errors
    const [error, setError] = useState(null);

    /**
     * @param {*} e 
     */
    const handleChangeName = (e) => {

        if(!e.target.value || !e.target.value.length > 50) setError("Veuillez préciser un nom, et faisant moins de 50 caractères.");
        else setError(null);

        setTeamName(e.target.value); console.log('changed')
    }
    
    /**
     * @param {*} e 
     */
    const handleChangeWidget = (e) => {

        if(e.target.value && e.target.value.length > 50) setError("Numéro de widget trop long.");
        else setError(null);

        setTeamWidget(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangeClassement = (e) => {

        if(e.target.value && !e.target.value.length > 50) setError("Numéro de classement trop long.");
        else setError(null);

        setTeamClassement(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangeDates = (e) => {

        if(e.target.value && !e.target.value.length > 50) setError("Entraînement trop long.");
        else setError(null);

        setTeamDates(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangeCoachName = (e) => {

        if(e.target.value && !e.target.value.length > 50) setError("Nom du coach trop long.");
        else setError(null);

        setTeamCoachName(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangeCoachPhone = (e) => {

        if(e.target.value && !e.target.value.length > 50) setError("Téléphone du coach trop long.");
        else setError(null);

        setTeamCoachPhone(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangeCategoryId = (e) => {

        if(!e.target.value) setError("Précisez une catégorie.");
        else setError(null);

        setTeamCategoryId(parseInt(e.target.value));

    }

    /**
     * @param {*} e 
     */
    const handleChangeImg = (e) => {

        const selectedFile = e.target.files[0];

        const maxSize = 5 * 1024 * 1024;
        if (selectedFile && selectedFile.size > maxSize) setError('La taille du fichier dépasse la limite autorisée.');
        else setError(null);

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (selectedFile && !allowedTypes.includes(selectedFile.type)) setError('Type de fichier non autorisé. Veuillez sélectionner une image.');
        else setError(null);
        
        setImg(selectedFile);

    }

    /**
     * @param {*} e 
     */
    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const formData = new FormData();

            formData.append("name", teamName);
            formData.append("widget", teamWidget);
            formData.append("classement", teamClassement);
            formData.append("dates", teamDates);
            formData.append("coachName", teamCoachName);
            formData.append("coachPhone", teamCoachPhone);
            formData.append("categoryId", teamCategoryId);
            formData.append("id", props.teamId ? props.teamId : 0);
            formData.append("img", img);

            const callAPI = await fetch(API_URL + "/team/update", {
                method: 'POST',
                headers: {
                    'Authorization': `TK ${cookieValue('auth')}`,
                },
                body: formData
            });

            if(callAPI.status === 400) throw new Error("Informations incorrectes.");

            navigate("/adm/teams/list");

        }
        catch(e) {
            setError(e.message);
        }

    }

    useEffect(() => {
        
        const fetchAPI = async () => {

            const callAPI = await fetch(API_URL + "/teamcategory/getall");
            const dataAPI = await callAPI.json();

            const tc = dataAPI.teamCategories;

            let formattedTeamCategories = [];

            tc.forEach((tc) => {
                formattedTeamCategories.push([tc.id, tc.name]);
            });

            setTeamCategories(formattedTeamCategories);

        }

        fetchAPI();

    }, []);

    return (

    <>

        <Form>

            <tr>
                <td></td>
                <td>
                    { error ? <ErrorMessage>{error}</ErrorMessage> : null }
                </td>
            </tr>

            <TextInput label="Nom de l'équipe" value={teamName} onChange={handleChangeName} />
            <TextInput label="Numéro de widget d'information" value={teamWidget}  onChange={handleChangeWidget} />
            <TextInput label="Numéro de widget de classement" value={teamClassement} onChange={handleChangeClassement} />
            <TextInput label="Date des entraînements séparées par un (-)" value={teamDates} onChange={handleChangeDates} />
            <TextInput label="Nom du coach" value={teamCoachName} onChange={handleChangeCoachName} />
            <TextInput label="Téléphone du coach" value={teamCoachPhone} onChange={handleChangeCoachPhone} />
            <SelectInput options={teamCategories} label="Catégorie" onChange={handleChangeCategoryId} value={teamCategoryId} />
            <ImageInput label="Image de l'équipe" onChange={handleChangeImg} />
            <ButtonField onClick={ handleSubmit }>Enregistrer</ButtonField>
        </Form>

    </>

    )


}

export default FormTeam;