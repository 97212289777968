import styled from "styled-components";
import Title from "../../Title/Title";
import { useEffect } from "react";

const TeamInfoBlockWrapper = styled.div `

    position:relative;
    display:block;
    width:800px;

    @media (max-width:500px) {
        width:80%;
    }

    & > div {

        position:relative;
        top:0;
        left:0;
        width:100%;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
        border-radius:12px;
        display:flex;
        justify-content:space-around;

        @media (max-width:500px) {
            flex-direction:column;
            justify-content:center;
            align-items:center;
        }

    }

    & > div > div {
        margin:20px 0;
    }

    & > div > div > h5 {
        font-size:16px;
        margin:0;
    }

    & > div > div > p {
        color:grey;
        margin:10px 0 0 0;
        font-size:14px;
        line-height:24px;
    }

`

const TeamInfoBlock = (props) => {

    const dates = props.datesTeam ? props.datesTeam.split("-") : [];
    const games = props.gamesTeam ? props.gamesTeam.split("-"): [];

    return (

    <TeamInfoBlockWrapper>

        <Title centered={true}>Informations</Title>

        <div>
            
            <div>
                <h5>Entraînements</h5>
                <p>
                    { dates.map((date) => <>{date}<br /></>)}
                </p>
            </div>

            <div>
                <h5>Responsable</h5>
                <p>
                    {props.coachNameTeam}<br />
                    {props.coachPhoneTeam}
                </p>
            </div>

            <div>
                <h5>Compétitions</h5>
                <p>
                    { games.map((game) => <>{game}<br /></>)}
                </p>
            </div>

        </div>

    </TeamInfoBlockWrapper>

    )

}

export default TeamInfoBlock;