import styled from "styled-components";

const TitleWrapper = styled.h2 `

    font-size:24px;
    margin:50px 0 30px 0;

    &.centered {
        text-align:center;
    }

`

const Title = (props) => {

    return <TitleWrapper className={props.centered ? "centered" : ""}>{props.children}</TitleWrapper>

}

export default Title;