import Menu from "./Menu/Menu";
import styled from 'styled-components';

const TopHeaderWrapper = styled.div`

    position: relative;
    top: 0;
    left:0;
    width: 100%;
    background: black;
    padding: 0 200px;

    @media (max-width:500px) {
        padding:0;
    }

`;

const TopHeader = () => {

    return (

    <TopHeaderWrapper>
        <Menu />
    </TopHeaderWrapper>

    );

}

export default TopHeader;