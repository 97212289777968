import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import FormTeam from "../../components/Admin/FormTeam/FormTeam";

const FlexDiv = styled.div `

    display:flex;
    flex-direction:row;
    align-items:start;

    & > img {
        width:500px;
        margin:46px 50px;
    }

`

const TeamCreate = () => {

    return (

    <>
        <PageTitle>Création d'une équipe</PageTitle>
        
        <FlexDiv>
            <FormTeam />
            <img src="https://placehold.co/600x400/png" />
        </FlexDiv>

    </>
    
    );
}

export default TeamCreate;