import styled from "styled-components";
import Col from "../Col/Col";

const StyledDeleteButton = styled.button `
    color:rgba(231, 76, 60,1.0);
    border:0;
    background:transparent;
    outline:0;
    font-size:24px;
    cursor:pointer;
`

const StyledUpdateButton = styled.button `
    color:rgba(52, 152, 219,1.0);
    border:0;
    background:transparent;
    outline:0;
    font-size:24px;
    cursor:pointer;
`

const DeleteButton = (props) => {
    return <StyledDeleteButton onClick={props.onClick ? props.onClick : null}><ion-icon name="trash"></ion-icon></StyledDeleteButton>
}

const UpdateButton = (props) => {
    return <StyledUpdateButton onClick={props.onClick ? props.onClick : null}><ion-icon name="create"></ion-icon></StyledUpdateButton>
}

const ActionTd = styled.td `
    text-align:right;
    padding-right:10px;
`

const Row = (props) => {

    return (

    <tr>
        { props.values ? props.values.map((value, index) => <Col key={index}>{ value }</Col>) : null }
        <ActionTd>
            { props.onDelete ? <DeleteButton onClick={props.onDelete} /> : null}
            { props.onUpdate ? <UpdateButton onClick={props.onUpdate} /> : null}
        </ActionTd>
    </tr>

    );

}

export default Row;