import { Outlet } from 'react-router-dom';
import styled from 'styled-components';


const ContentWrapper = styled.div `

    background:white;
    margin:50px;
    -webkit-box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.4); 
    box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.4);
    border-radius:10px;
    width:calc(100% - 290px);
    overflow:auto;

`

const Content = () => {

    return (

    <ContentWrapper>
        <Outlet />
    </ContentWrapper>
    
    );

}

export default Content;