import { Link } from "react-router-dom";
import styled from "styled-components";

const ShopAdvertismentWrapper = styled(Link) `

    text-decoration:none;

    & > div {
        position:relative;
        top:0;
        left:0;
        width:800px;
        height:120px;
        margin:60px 0;
        background:linear-gradient(to bottom, rgba(241, 196, 15, 0.05), rgba(241, 196, 15,0.3)), url('https://media.sudouest.fr/10630590/1000x500/burdi.jpg?v=1650181972');
        background-size:cover;
        text-align:center;
        border-radius:20px;
        display:flex;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        transition:all .3s ease;

        @media (max-width:500px) {
            width:90%;
            margin:5%;
        }


    }

    & > div > p {

        text-transform:uppercase;
        color:white;
        font-size:28px;
        font-weight:600;
        margin:0;
        text-shadow: 2px 2px 5px rgba(0,0,0,0.23);

        @media (max-width:500px) {
            font-size:18px;
        }

    }

    & > div > p > span {
        color:#f1c40f;
    }

    & > div:hover {
        transform:scale(1.01);
        transition:all .3s ease;
    }


`

const ShopAdvertisment = () => {

    return (

    <ShopAdvertismentWrapper to="/shop">
        <div>
            <p>Découvrez notre <span>boutique officielle</span></p>
        </div>
    </ShopAdvertismentWrapper>
    
    );

}

export default ShopAdvertisment;