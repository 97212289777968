import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"
import Input from "../Input/Input"
import Button from "../Button/Button"
import { useState, useEffect } from "react"
import { API_URL, cookieValue } from "../../../utils"

const LoginBoxWrapper = styled.div `

    background:white;
    -webkit-box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.4); 
    box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.4);
    border-radius:10px;
    display:flex;
    flex-direction:column;

    & > div:first-child {
        display:flex;
        flex-direction: row;
        justify-content:center;
    }

    & > div:first-child > img {
        width:150px;
        margin:30px 0;
    }

    & > div:nth-child(2) {
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
    }

    & > div:nth-child(2) > h2 {
        margin:0;
        font-size:22px;
        font-weight:bold;
    }

    & > div:nth-child(2) > h3 {
        margin:4px 0;
        font-size:14px;
        font-weight:500;
        text-transform:uppercase;
        color:grey;
    }

    & > div:nth-child(3) {
        display:flex;
        flex-direction:column;
        padding:30px 50px;
    }

    & > div:nth-child(5) {
        display:flex;
        flex-direction:row;
        justify-content:center;
    }

`

const ErrorText = styled.p `

    font-size:13px;
    font-weight:bold;
    color:red;
    text-align:center;
    margin:0 0 20px 0;

`

const LoginBox = () => {

    const navigate = useNavigate();

    // Form fields
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    // Control of existing cookie
    useEffect(() => {
        const existing_cookie = cookieValue('auth');
        if(existing_cookie) navigate("/adm/teams/list");
    });

    /**
     * @param {*} e 
     */
    const handleChangeEmail = (e) => {
        setError(null);
        setEmail(e.target.value);
    }

    /**
     * @param {*} e 
     */
    const handleChangePassword = (e) => {
        setError(null);
        setPassword(e.target.value);
    }

    const handleLogin = async () => {

        try {

            if(email && password) {

                const callAPI = await fetch(API_URL + "/user/authenticate", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password
                    })
                });

                if(callAPI.status === 403) {
                    throw new Error("Identifiant ou mot de passe incorrect.");
                }

                const dataAPI = await callAPI.json();

                const token = dataAPI.token;

                const expirationInDays = 7;
                const expiration = new Date();
                expiration.setDate(expiration.getDate() + expirationInDays);
                const cookieString = `auth=${token}; expires=${expiration.toUTCString()}; path=/`;
                document.cookie = cookieString;

                navigate("/adm/teams/list");

            } else {
                throw new Error("Veuillez entrer tous les champs.");
            }

        } catch(e) {
            setError(e.message);
        }

    }

    return (

    <LoginBoxWrapper>

        <div>
            <img src="/img/logo-jsa.png" alt="Logo JSA" />
        </div>

        <div>
            <h2>Connexion</h2>
            <h3>Espace entraîneur</h3>
        </div>

        <div>
            <Input type="email" placeholder="Votre email" onChange={handleChangeEmail} />
            <Input type="password" placeholder="Votre mot de passe" onChange={handleChangePassword} />
            <Button onClick={handleLogin}>Se connecter</Button>
        </div>

        <div>
            {error ? <ErrorText>{error}</ErrorText> : ""}
        </div>

    </LoginBoxWrapper>

    )

}

export default LoginBox;