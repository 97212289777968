import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Title from "../../Title/Title";

const TeamInfoWidgetWrapper = styled.div `

    display:flex;
    flex-direction:column;
    margin:20px 0 0 0;  

    @media (max-width:500px) {
        width:80%;
    }

    & p {
        text-align:center;
        color:grey;
        font-size:14px;
        margin:0 0 40px 0;
    }

    & p span {
        padding:0 10px;
        cursor:pointer;
    }
    
    & iframe {

        display:block;
        width:800px;
        overflow:auto;
        margin:auto;
        border-width:0px;

        @media (max-width:500px) {
            width:100%;
        }

    }

`

const TeamInfoWidget = (props) => {

    return (

    <TeamInfoWidgetWrapper>
        <Title centered={true}>Classement, calendrier et résultats</Title>
        <iframe id={props.widgetId} height="1200" src={"https://v1.scorenco.com/widget/" + props.widgetId + "/"}></iframe>
    </TeamInfoWidgetWrapper>

    );
    
}

export default TeamInfoWidget;