import styled from "styled-components"

const RedParagraph = styled.p `
    color:rgba(231, 76, 60,1.0);
    font-weight:bold;
    font-size:16px;
`

const ErrorMessage = (props) => {
    return <RedParagraph>{ props.children }</RedParagraph>
}

export default ErrorMessage;