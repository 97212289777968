import styled from "styled-components";
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import FormPlayer from "../../components/Admin/FormPlayer/FormPlayer";

const FlexDiv = styled.div `

    display:flex;
    flex-direction:row;
    align-items:start;

    & > img {
        width:200px;
        margin:46px 50px;
    }

`

const PlayerCreate = () => {

    return (

    <>
        <PageTitle>Création d'un joueur</PageTitle>
        
        <FlexDiv>
            <FormPlayer />
            <img src="https://placehold.co/400x600/png" />
        </FlexDiv>

    </>

    );
}

export default PlayerCreate;