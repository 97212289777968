import styled from 'styled-components';

const BannerWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:400px;
    background:linear-gradient(to bottom, rgba(241, 196, 15, 0.05), rgba(241, 196, 15,0.3)), url('/img/bg3.png');
    background-size:cover;
    display:flex;
    justify-content:center;
    align-items:center;

    & h2 {
        text-transform:uppercase;
        color:white;
        font-size:60px;
        text-shadow: 2px 2px 9px rgba(0,0,0,0.45);
    }

    @media (max-width: 500px) {
        text-align:center;
    }

`

const Banner = () => {

    return (

    <BannerWrapper>
        <h2 id="titlepage"></h2>
    </BannerWrapper>
    
    );

}

export default Banner;