import styled from "styled-components";

const TableBodyWrapper = styled.tbody `

    & tr:nth-child(even) {
        transition:all .3s ease;
        background:#ecf0f1;
    }

    & tr:hover {
        transition:all .3s ease;
        background:rgba(189, 195, 199,1.0);
    }

`

const TableBody = (props) => {
    return <TableBodyWrapper>{ props.children }</TableBodyWrapper>
}

export default TableBody;