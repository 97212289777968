import React from 'react';
import reportWebVitals from './reportWebVitals';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Interface/Home";
import Interface from "./pages/Interface";
import Security from "./pages/Security";
import Teams from "./pages/Interface/Teams";
import Team from './pages/Interface/Team';
import History from './pages/Interface/History';
import Shop from './pages/Interface/Shop';
import Login from './pages/Security/Login';
import TeamList from './pages/Admin/TeamList';
import Admin from './pages/Admin';
import TeamUpdate from './pages/Admin/TeamUpdate';
import TeamCreate from './pages/Admin/TeamCreate';
import PlayerList from './pages/Admin/PlayerList';
import PlayerCreate from './pages/Admin/PlayerCreate';
import PlayerUpdate from './pages/Admin/PlayerUpdate';
import Logout from './pages/Security/Logout';
import UserList from './pages/Admin/UserList';
import UserCreate from './pages/Admin/UserCreate';
import UserUpdate from './pages/Admin/UserUpdate';
import SponsorList from './pages/Admin/SponsorList';
import SponsorUpdate from './pages/Admin/SponsorUpdate';
import SponsorCreate from './pages/Admin/SponsorCreate';
import Legal from './pages/Interface/Legal';

export default function App() {

  return (

  <BrowserRouter>
    <Routes>

      <Route path="/" element={<Interface />}>
        <Route index element={<Home />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/team/:id" element={<Team />} />
        <Route path="/history" element={<History />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/legal" element={<Legal />} />
      </Route>

      <Route path="/sec" element={<Security />}>
        <Route index element={<Login />} />
        <Route path="/sec/login" element={<Login />} />
        <Route path="/sec/logout" element={<Logout />} />
      </Route>

      <Route path="/adm" element={<Admin />}>
        <Route index element={<TeamList />} />
        <Route path="/adm/teams/list" element={<TeamList />} />
        <Route path="/adm/teams/update/:id" element={<TeamUpdate />} />
        <Route path="/adm/teams/create" element={<TeamCreate />} />
        <Route path="/adm/players/list" element={<PlayerList />} />
        <Route path="/adm/players/update/:id" element={<PlayerUpdate />} />
        <Route path="/adm/players/create" element={<PlayerCreate />} />
        <Route path="/adm/users/list" element={<UserList />} />
        <Route path="/adm/users/update/:id" element={<UserUpdate />} />
        <Route path="/adm/users/create" element={<UserCreate />} />
        <Route path="/adm/sponsors/list" element={<SponsorList />} />
        <Route path="/adm/sponsors/update/:id" element={<SponsorUpdate />} />
        <Route path="/adm/sponsors/create" element={<SponsorCreate />} />
      </Route>

    </Routes>
  </BrowserRouter>

  );

}
      
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

