import styled from "styled-components"

const GreenParagraph = styled.p `
    color:rgba(46, 204, 113,1.0);
    font-weight:bold;
    font-size:16px;
`

const ConfirmMessage = (props) => {
    return <GreenParagraph>{ props.children }</GreenParagraph>
}

export default ConfirmMessage;