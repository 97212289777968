import { Link } from "react-router-dom";
import styled from "styled-components";
import { API_URL } from "../../../../../utils";

const dynamicStyles = (props) => "background-image: url(" + props.bgimage + ");";

const TeamBlockWrapper = styled(Link) `

    text-decoration:none;
    color:inherit;

    & > div {

        width:300px;
        display:flex;
        flex-direction:column;
        margin:0 28px 28px 0;
        border-radius:12px;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
        transition:all .2s ease;

        @media (max-width:500px) {
            margin:10px 0;
        }

    }

    & > div:hover {
        transition:all .2s ease;
        transform:scale(1.02);
    }

    & > div > div:first-child {
        ${dynamicStyles} 
        height:160px;
        border-top-left-radius:12px;
        border-top-right-radius:12px;
        background-size:cover;
    }

    & > div > div:nth-child(2) {
        height:46px;
        border-bottom-left-radius:12px;
        border-bottom-right-radius:12px;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    & > div > div:nth-child(2) h2 {
        margin:0;
    }

`

const TeamBlock = (props) => {

    const teamImg = props.teamImg ? API_URL + "/team/getimage?teamid=" + props.teamId : "https://placehold.co/600x400/png";

    return (

    <TeamBlockWrapper to={"/team/" + props.teamId } bgimage={teamImg}>
        <div>
            <div></div>
            <div>
                <h4>{props.teamName}</h4>
            </div>
        </div>
    </TeamBlockWrapper>
    
    );  
}

export default TeamBlock;