import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Title from "../../Title/Title";
import { API_URL } from '../../../../utils';

const ClassementWrapper = styled.div `

    display:flex;
    flex-direction:column;
    margin:20px 0 0 0;  

    & p {
        text-align:center;
        color:grey;
        font-size:14px;
        margin:0 0 40px 0;
    }

    & p span {
        padding:0 10px;
        cursor:pointer;
    }
    
    & iframe {
        display:block;
        width:800px;
        overflow:auto;
        margin:auto;
        border-width:0px;

        @media (max-width:500px) {
            width:100%;
            margin:0;
        }

    }

`

const Classement = (props) => {

    const [teams, setTeams] = useState([]);
    const [teamId, setTeamId] = useState(0);

    useEffect(() => {

        const fetchAPI = async () => {
            
            const apiCall = await fetch(API_URL + "/team/getall");
            const apiResults = await apiCall.json();

            if(apiResults.teams.length === 0) {
                setTeams([]);
                setTeamId(0);
            }
            else {
                setTeams(apiResults.teams.filter((t) => t.classement));
            }

        }

        fetchAPI();

    }, []);

    useEffect(() => {

        setTeamId(0);

        if(teams && props.teamId) {
            teams.forEach((t, i) => {
                if(t.teamId === parseInt(props.teamId)) setTeamId(i);
            });
        }

    }, [props.teamId, teams]);

    /**
     * @param {*} inc 
     */
    const handleChange = (inc) => {
        if(teamId + inc < 0) setTeamId(teams.length - 1);
        else setTeamId((teamId + inc) % teams.length);
    }

    return (

    teams.length !== 0 && 

    <ClassementWrapper>
        <Title centered={true}>Classement</Title>
        { !props.teamId ? <p><span onClick={() => {handleChange(-1)}}>{'<'} </span>{teams[teamId].name}<span onClick={() => {handleChange(1)}}> {'>'}</span></p> : "" }
        <iframe id={teams[teamId].classement} height="500" src={"https://v1.scorenco.com/widget/" + teams[teamId].classement + "/"}></iframe>
    </ClassementWrapper>
        
    );

    
}

export default Classement;