import styled from "styled-components"

const StyledInput = styled.input `

    width:400px;
    height:38px;
    border:0;
    background:#ecf0f1;
    padding-left:8px;
    transition:all .3s ease;
    outline:0;

    &:hover {
        transition:all .3s ease;
        background:rgba(189, 195, 199,1.0);
    }

`

const TextInput = (props) => {

    return (

    <tr>
        <td>
            { props.label ? <label>{props.label} </label> : null }
        </td>
        <td>
            <StyledInput type="text" value={props.value ? props.value : ""} onChange={props.onChange ? props.onChange : null} placeholder={props.placeholder ? props.placeholder : ""} />
        </td>
    </tr>

    );
    
}

export default TextInput;