import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from  "react-router-dom";
import styled from "styled-components";
import TeamGroupPicture from "../../components/Interface/Teams/TeamGroupPicture/TeamGroupPicture";
import TeamEffectif from "../../components/Interface/Teams/TeamEffectif/TeamEffectif";
import TeamInfoWidget from "../../components/Interface/Teams/TeamInfoWidget/TeamInfoWidget";
import ShopAdvertisment from "../../components/Interface/ShopAdvertisment/ShopAdvertisment";
import TeamInfoBlock from "../../components/Interface/Teams/TeamInfoBlock/TeamInfoBlock";
import { API_URL } from "../../utils";

const TeamWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

`

const Team = () => {

    const navigate = useNavigate();

    const {id} = useParams();

    const [team, setTeam] = useState(null);

    useEffect(() => {

        const fetchAPI = async () => {

            try {

                const callAPI = await fetch(API_URL + "/team/get?teamid=" + id);
                const dataAPI = await callAPI.json();

                if(callAPI.status === 404) {
                    throw new Error("L'équipe n'existe pas.");
                }

                setTeam(dataAPI.team);

            }
            catch(e) {
                navigate('/teams');
            }

        }

        fetchAPI();


    }, []);

    useEffect(() => {
        const titlepage = document.getElementById("titlepage");
        titlepage.innerText = team ? team.name : "";
        const titlehtml = document.querySelector("title");
        titlehtml.innerText = "JSA Volley Ball - " + (team ? team.name : null);

    });

    return (

    team &&

    <TeamWrapper>

        <TeamGroupPicture teamImg={team.img} teamId={id} />
        <TeamEffectif teamId={id} />
        <TeamInfoWidget widgetId={team.widget} />
        <TeamInfoBlock coachNameTeam={team.coach_name} coachPhoneTeam={team.coach_phone} gamesTeam={team.games} datesTeam={team.dates} />
        <ShopAdvertisment />

    </TeamWrapper>

    );
}


export default Team;