import styled from "styled-components";
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import FormSponsor from "../../components/Admin/FormSponsor/FormSponsor";

const FlexDiv = styled.div `
    display:flex;
    flex-direction:row;
    align-items:start;

    & > img {
        width:200px;
        margin:46px 50px;
    }

`

const SponsorCreate = () => {

    return (
        <>
            <PageTitle>Création d'un sponsor</PageTitle>
            
            <FlexDiv>
                <FormSponsor />
                <img src="https://placehold.co/400x400/png" />
            </FlexDiv>

        </>
    );
}

export default SponsorCreate;