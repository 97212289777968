import styled from "styled-components";
import Title from "../../Title/Title";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../utils";
import TeamBlock from "./TeamBlock/TeamBlock";

const TeamCategoryWrapper = styled.div `

    position:relative;
    display:block;
    width:60%;

    @media (max-width: 500px) {
        text-align:center;
        width:90%;
    }

    & > div {

        width:100%;
        display:flex;
        flex-wrap:wrap;
        justify-content:start;

        @media (max-width: 500px) {
            justify-content:center;
        }

    }

`

const TeamCategory = (props) => {

    const [teams, setTeams] = useState([]);

    useEffect(() => {

        const fetchAPI = async () => {

            try {

                const apiCall = await fetch(API_URL + "/team/getallofcategory?teamcategoryid=" + props.teamCategoryId);
                const apiResults = await apiCall.json();

                setTeams(apiResults.teams);

            }
            catch(e) {
                console.log(e);
            }

        }

        fetchAPI();

    }, []);

    return (

    <TeamCategoryWrapper>

        <Title>{ props.teamCategoryName }</Title>

        <div>
            { teams.map((team, index) => <TeamBlock key={index} teamId={team.id} teamName={team.name} teamImg={team.img} />)}
        </div>

    </TeamCategoryWrapper>

    );

}

export default TeamCategory;