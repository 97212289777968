import Sidebar from "../components/Admin/Sidebar/Sidebar";
import Content from "../components/Admin/Content/Content";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { API_URL, cookieValue, deleteCookie } from "../utils";
import { useNavigate } from "react-router-dom";

const BodyWithFlexBehavior = styled.div `
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: stretch;
    background:#ecf0f1;
`

const Admin = () => {

    const navigate = useNavigate();

    const [user, setUser] = useState(null);

    useEffect(() => {

        const existing_cookie = cookieValue('auth');
        if(!existing_cookie) navigate("/sec/login");

        const fetchAPI = async () => {
           
            const callAPI = await fetch(API_URL + "/user/checkjwt?jwt=" + existing_cookie);
            const dataAPI = await callAPI.json();

            if(callAPI.status === 403) navigate("/sec/logout");

            setUser({
                id: dataAPI.id,
                email: dataAPI.email
            });

        }

        fetchAPI();

    }, []);

    return (
        
    <BodyWithFlexBehavior>
        <Sidebar userEmail={user ? user.email : null} />
        <Content />
    </BodyWithFlexBehavior>

    );

}

export default Admin;