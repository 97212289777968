import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link) `

    text-decoration:none;
    width:100%;
    line-height:60px;
    padding:0 0 0 20px;
    color:black;
    font-size:16px;
    font-weight:bold;
    transition:all .3s ease;

    & ion-icon {
        font-size:22px;
        transform:translateY(3px);
        padding-right:10px;
    }

    &:hover {
        background:#ecf0f1;
        transition:all .3s ease;
    }

    &.active {
        background:rgba(241, 196, 15,1.0);
    }

`

const SidebarWrapper = styled.header `

    background:white;
    margin:50px;
    width:290px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    -webkit-box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.4); 
    box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.4);
    border-radius:10px;

    & > div:first-child {
        display:flex;
        justify-content: center;
    }

    & > div:first-child > img {
        width:100px;
        margin:20px 0;
    }

    & > div:nth-child(2) {
        display:flex;
        flex-direction:column;
    }

    & > div:nth-child(3) {
        display:flex;
        flex-direction:column;
    }

    & > div:nth-child(3) > p {
        font-size:14px;
        width:100%;
        text-align:center;
    }

    & > div:nth-child(3) > p > span {
        color:rgba(241, 196, 15,1.0);
        font-weight:bold;
    }

`

const Sidebar = (props) => {

    const location = useLocation().pathname;
    const entity = location.split('/')[2];

    return (

    <SidebarWrapper>
        
        <div>
            <img src="/img/logo-jsa.png" />
        </div>

        <div>
            <StyledLink to="/adm/teams/list" className={entity === 'teams' ? 'active' : ''}><ion-icon name="people"></ion-icon>Gestion des équipes</StyledLink>
            <StyledLink to="/adm/players/list" className={entity === 'players' ? 'active' : ''}><ion-icon name="person"></ion-icon>Gestion des joueurs</StyledLink>
            <StyledLink to="/adm/sponsors/list" className={entity === 'sponsors' ? 'active' : ''}><ion-icon name="pricetags"></ion-icon>Gestion des sponsors</StyledLink>
        </div>

        <div>
            <p>Connecté en tant que <span>{ props.userEmail}</span></p>
            <StyledLink to="/sec/logout"><ion-icon name="log-out"></ion-icon>Déconnexion</StyledLink>
            <StyledLink to="/adm/users/list" className={entity === 'users' ? 'active' : ''}><ion-icon name="lock-closed"></ion-icon>Gestion des accès</StyledLink>
        </div>

    </SidebarWrapper>
    
    );

}

export default Sidebar;