import styled from "styled-components"

const FormWrapper = styled.table `

    margin:40px 0 0 50px;

    & > tbody > tr > td {
        padding:4px;
    }

`

const Form = (props) => {

    return (

    <form encType="multipart/form-data">
        <FormWrapper>
            <tbody>
                { props.children }
            </tbody>
        </FormWrapper>
    </form>
        
    );

}

export default Form;