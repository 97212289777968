import styled from "styled-components";
import { API_URL } from "../../../../utils";

const TeamGroupPictureWrapper = styled.div `

        width:60%;
        margin:40px 0;

        @media (max-width:500px) {
            width:95%;
            margin:10px 0;
        }

        & img {
            width:100%;
            border-radius:20px;
        }

`

const TeamGroupPicture = (props) => {

    const teamImg = props.teamImg ? API_URL + "/team/getimage?teamid=" + props.teamId : "https://placehold.co/600x400/png";

    return (

    <TeamGroupPictureWrapper>
        <img src={teamImg} />
    </TeamGroupPictureWrapper>

    );

}

export default TeamGroupPicture;