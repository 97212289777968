import styled from "styled-components"
import Title from "../../Title/Title";

const InfoClubWrapper = styled.div `

    position:relative;
    display:block;
    width:60%;

    @media (max-width:500px) {
        width:80%;
    }

    & > div {

        width:100%;
        position:relative;
        top:0;
        left:0;
        display:flex;
        justify-content:space-between;

        @media (max-width:500px) {
            flex-direction:column;
        }

    }

    & > div > div > p {

        text-align:justify;
        width:80%;

        @media (max-width:500px) {
            width:100%;
        }

    }

    & > div > div.img {
        @media (max-width: 500px) {
            text-align:center;
        }
    }

    & > div > div > img {

        width:200px;

    }

`

const InfoClub = () => {

    return (

    <InfoClubWrapper>

        <Title>Les JSA, c'est tout une histoire</Title>
        
        <div>

            <div>
                <p>Les Jeunes de Saint-Augustin Bordeaux Volley-Ball est un club français de volley-ball, fondé en 1961. C'est une section du club omnisports des Jeunes de Saint-Augustin, basé à Bordeaux. Les JSA Bordeaux Volley évoluent en Championnat de France de Nationale 2 de volley-ball masculin et en Championnat de France de Nationale 2 de volley-ball féminin.</p>
            </div>

            <div className="img">
                <img src="/img/logo-jsa.png" />
            </div>

        </div>

    </InfoClubWrapper>

    );

}

export default InfoClub;