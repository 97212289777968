import { useEffect, useState } from "react";
import Form from "../Form/Form";
import TextInput from "../Form/TextInput/TextInput";
import ButtonField from "../Form/ButtonField/ButtonField";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { API_URL, cookieValue } from "../../../utils";
import PasswordInput from "../Form/PasswordInput/PasswordInput";

const FormUser = (props) => {

    const navigate = useNavigate();

    // Form fields
    const [userName, setUserName] = useState(props.userName ? props.userName : "");
    const [userFirstname, setUserFirstname] = useState(props.userFirstname ? props.userFirstname : "");
    const [userEmail, setUserEmail] = useState(props.userEmail ? props.userEmail : "");
    const [userPassword, setUserPassword] = useState("");

    // Errors
    const [error, setError] = useState(null);

    /**
     * @param {*} e 
     */
    const handleChangeName = (e) => {

        if(!e.target.value || !e.target.value.length > 50) setError("Veuillez préciser un nom, et faisant moins de 50 caractères.");
        else setError(null);

        setUserName(e.target.value);
    }
    
    /**
     * @param {*} e 
     */
    const handleChangeFirstname = (e) => {

        if(!e.target.value || e.target.value.length > 50) setError("Veuillez préciser un prénom, et faisant moins de 50 caractères.");
        else setError(null);

        setUserFirstname(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangeEmail = (e) => {

        if(!e.target.value && e.target.value.length > 50) setError("Veuillez préciser un email, et faisant moins de 50 caractères.");
        else setError(null);

        setUserEmail(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangePassword = (e) => {

        if(!e.target.value || e.target.value.length > 50 || e.target.value.length < 8) setError("Veuillez préciser un mot de passe, et faisant entre 8 et 50 caractères.");
        else setError(null);

        setUserPassword(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const formData = new FormData();

            formData.append("name", userName);
            formData.append("firstname", userFirstname);
            formData.append("email", userEmail);
            formData.append("password", userPassword);
            formData.append("id", props.userId ? props.userId : 0);

            const callAPI = await fetch(API_URL + "/user/update", {
                method: 'POST',
                headers: {
                    'Authorization': `TK ${cookieValue('auth')}`,
                },
                body: formData
            });

            if(callAPI.status === 400) throw new Error("Informations incorrectes.");

            navigate("/adm/users/list");

        }
        catch(e) {
            setError(e.message);
        }

    }

    return (

    <>

        <Form>

            <tr>
                <td></td>
                <td>
                    { error ? <ErrorMessage>{error}</ErrorMessage> : null }
                </td>
            </tr>

            <TextInput label="Prénom" value={userFirstname} onChange={handleChangeFirstname} />
            <TextInput label="Nom" value={userName}  onChange={handleChangeName} />
            <TextInput label="Email" value={userEmail} onChange={handleChangeEmail} />
            <PasswordInput label="Mot de passe" value={userPassword} onChange={handleChangePassword} />
            <ButtonField onClick={ handleSubmit }>Enregistrer</ButtonField>
            
        </Form>

    </>

    )


}

export default FormUser;