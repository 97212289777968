import styled from "styled-components"

const StyledTd = styled.td `
    padding:10px 8px;
    font-size:14px;
`

const Col = (props) => {
    return <StyledTd>{props.children}</StyledTd>
}

export default Col;