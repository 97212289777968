import TopHeader from "./TopHeader/TopHeader";
import MainHeader from "./MainHeader/MainHeader";
import Banner from "./Banner/Banner";
import styled from "styled-components";

const HeaderWrapper = styled.header `

    position:relative;
    top:0;
    left:0;
    width:100%;

`;

const Header = () => {

    return (

    <HeaderWrapper>
        <TopHeader />
        <MainHeader />
        <Banner />
    </HeaderWrapper>

    );

}

export default Header;