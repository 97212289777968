import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, Link, useNavigate, useParams } from "react-router-dom"
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import FormTeam from "../../components/Admin/FormTeam/FormTeam";
import { API_URL, cookieValue } from "../../utils";

const FlexDiv = styled.div `

    display:flex;
    flex-direction:row;
    align-items:start;

    & > img {
        width:500px;
        margin:46px 50px;
    }

`

const TeamUpdate = () => {

    const navigate = useNavigate();

    const {id} = useParams();
    const teamId = parseInt(id ? id : 0);

    const [team, setTeam] = useState(null);

    useEffect(() => {

        const fetchAPI = async () => {

            try {

                const callAPI = await fetch(API_URL + "/team/get?teamid=" + teamId, {
                    headers: {
                        'Authorization': `TK ${cookieValue('auth')}`,
                    }
                });
                const dataAPI = await callAPI.json();

                if(callAPI.status === 404) throw new Error("L'équipe n'existe pas.");
                
                setTeam(dataAPI.team);

            }
            catch(e) {
                navigate('/adm/teams/')
            }

        }

        fetchAPI();

    }, []);

    return (

    team &&
    <>

        <PageTitle>Edition : { team.name }</PageTitle>
        <FlexDiv>
            <FormTeam teamId={team.id} teamName={team.name} teamWidget={team.widget} teamClassement={team.classement} teamDates={team.dates} teamCoachName={team.coach_name} teamCoachPhone={team.coach_phone} teamGames={team.games} teamCategoryId={team.TeamCategoryId} />
            <img src={team.img ? process.env.REACT_APP_API_HOST + ":" + process.env.REACT_APP_API_PORT + "/team/getimage?teamid=" + team.id : "https://placehold.co/600x400/png"} />
        </FlexDiv>

    </>
    
    );
}

export default TeamUpdate;