import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Title from "../Title/Title";

const LastPostsWrapper = styled.div `

    display:flex;
    flex-direction:column;
    margin:50px 0 0 0;
    width:100%;
    padding:20px 0; 
    display:flex;
    justify-content:center;
    align-items:center;

    & > div {
        display:flex;
        flex-direction:column;
        margin:10px 0 0 0;
    }

    & > div > div {
        display:flex;
        flex-direction:row;
    }

    & > div > div > img {
        margin:5px;
        width:300px;
    }

`

const LastPosts = () => {

    return (

    <LastPostsWrapper>

        <Title centered={true}>Galerie</Title>

        <div>
            <div>
                <img src="https://placehold.co/400x400/png" />
                <img src="https://placehold.co/400x400/png" />
                <img src="https://placehold.co/400x400/png" />
            </div>
            <div>
                <img src="https://placehold.co/400x400/png" />
                <img src="https://placehold.co/400x400/png" />
                <img src="https://placehold.co/400x400/png" />
            </div>
            <div>
                <img src="https://placehold.co/400x400/png" />
                <img src="https://placehold.co/400x400/png" />
                <img src="https://placehold.co/400x400/png" />
            </div>
        </div>

    </LastPostsWrapper>

    );

}

export default LastPosts;