import styled from "styled-components";
import { API_URL } from '../../../../utils';

const PlayerBlockWrapper = styled.div `

    border-radius:12px;
    position:relative;
    margin:14px;
    width:180px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    flex-direction:column;
    transition:all .2s ease;
    overflow:hidden;

    &:hover {
        transform:scale(1.01);
        transition:all .2s ease;
    }

    & > div:first-child {
        width:100%;
        height:160px;
    }

    & > div:first-child p {
        position:absolute;
        top:0;
        left:0;
        margin:14px;
        color:#f1c40f;
        font-weight:bold;
        font-size:16px;
    }

    & > div:nth-child(2) {
        background:#34495e;
        border-bottom-left-radius:12px;
        border-bottom-right-radius:12px;
    }

    & > div:nth-child(2) h4 {
        padding:5px 0 0 0;
        color:white;
        text-align:center;
        font-size:14px;
        margin:0;
    }

    & > div:nth-child(2) h5 {
        padding:0 0 5px 0;
        margin:0;
        text-align:center;
        font-weight:normal;
        color:#95a5a6;
        font-size:12px;
    }

`;

const PlayerBlock = (props) => {

    const playerImg = props.playerImg ? API_URL + "/player/getimage?playerid=" + props.playerId : "https://placehold.co/400x600/png"

    const bgImage = {
        backgroundImage: "url(" + playerImg + ")",
        backgroundSize: "cover"
    }

    return (

    <PlayerBlockWrapper>
        
        <div style={bgImage}>
            <p>#{props.playerNb}</p>
        </div>

        <div>
            <h4>{ props.playerName + " " + props.playerFirstname }</h4>
            <h5>{ props.playerPoste }</h5>
        </div>

    </PlayerBlockWrapper>

    );

}

export default PlayerBlock;