import styled from "styled-components";
import Title from "../../Title/Title";
import { useEffect, useState } from "react";
import PlayerBlock from "../../Players/PlayerBlock/PlayerBlock";
import { API_URL } from "../../../../utils";

const TeamEffectifWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:50%;

    @media (max-width:500px) {
        width:90%;
    }

    & > div {
        position:relative;
        width:100%;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
    }

    & > table {
        width:100%;
        margin:50px 0;
        border-collapse:collapse;
    }

    & > table > thead > tr > td {
        background:#34495e;
        line-height:48px;
        font-size:16px;
        padding:0 0 0 8px;
        font-weight:bold;
        color:white;
    }

    & > table > tbody > tr > td {
        line-height:48px;
        font-size:16px;
        padding:0 0 0 8px;
        color:#34495e;
        font-weight:500;
    }

    & > table > tbody > tr:nth-child(even) {
        background:rgba(0,0,0,0.03);
    }

    & > table > tbody > tr {
        transition:all .2s ease;
    }

    & > table > tbody > tr:hover {
        background:rgba(241, 196, 15, 0.3);
        transition:all .2s ease;
    }

`

const TeamEffectif = (props) => {

    const [players, setPlayers] = useState([]);

    useEffect(() => {

        const teamId = props.teamId;

        const fetchAPI = async () => {

            try {

                const callAPI = await fetch(API_URL + "/player/getallofateam?teamid=" + teamId);
                const dataAPI = await callAPI.json();

                if(callAPI.status === 404) throw Error("Equipe non existante.");

                setPlayers(dataAPI.players.sort((a, b) => a.nb - b.nb));

            }
            catch(e) {
                console.log(e);
            }

        }

        fetchAPI();


    }, []);

    return (

    <TeamEffectifWrapper>

        <Title centered={true}>Effectif 2023-2024</Title>

        <div>

            { players.map((player) => <PlayerBlock key={player.id} playerName={player.name} playerFirstname={player.firstname} playerPoste={player.poste} playerNb={player.nb} playerImg={player.img} playerId={player.id} />) }

        </div>

        <table>
            
            <thead>
                <tr>
                    <td>#</td>
                    <td>Nom</td>
                    <td>Poste</td>
                </tr>
            </thead>

            <tbody>

                { players.map((player, index) => <tr key={index}>
                    <td>{ player.nb }</td>
                    <td>{ player.name + " " + player.firstname }</td>
                    <td>{ player.poste }</td>
                </tr>) }

            </tbody>

        </table>

    </TeamEffectifWrapper>

    );

}

export default TeamEffectif;