import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom"
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import FormUser from "../../components/Admin/FormUser/FormUser";
import { API_URL, cookieValue } from "../../utils";

const FlexDiv = styled.div `

    display:flex;
    flex-direction:row;
    align-items:start;

    & > img {
        width:200px;
        margin:46px 50px;
    }

`

const UserUpdate = () => {

    const navigate = useNavigate();

    const {id} = useParams();
    const userId = parseInt(id ? id : 0);

    const [user, setUser] = useState(null);

    useEffect(() => {

        const fetchAPI = async () => {

            try {

                const callAPI = await fetch(API_URL + "/user/get?userid=" + userId, {
                    headers: {
                        'Authorization': `TK ${cookieValue('auth')}`,
                    }
                });
                const dataAPI = await callAPI.json();

                if(callAPI.status === 404) throw new Error("L'utilisateur n'existe pas.");
                
                setUser(dataAPI.user);

            }
            catch(e) {
                navigate('/adm/teams/')
            }

        }

        fetchAPI();

    }, []);

    return (

    user &&

    <>

        <PageTitle>Edition : { user.firstname + " " + user.name }</PageTitle>
        <FlexDiv>
            <FormUser userName={user.name} userFirstname={user.firstname} userEmail={user.email} userId={user.id} />
        </FlexDiv>

    </>
        
    );
}

export default UserUpdate;