import styled from "styled-components";

const StyledTHead = styled.thead `

    background:black;

    & > tr > td {
        padding:10px 8px;
        font-weight:bold;
        text-transform:uppercase;
        font-size:14px;
        color:white;
    }

`

const AlignRightTD = styled.td `
    text-align:right;
`

const TableHead = (props) => {

    return (

    <StyledTHead>
        <tr>
            { props.values ? props.values.map((value, index) => <td key={index}>{ value }</td>) : null }
            { props.commands ? <AlignRightTD>Actions</AlignRightTD> : null }
        </tr>
    </StyledTHead>
    
    )

}

export default TableHead;