import ENVIRONMENT from "./env";

const cookieValue = (name_cookie) => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === name_cookie) {
            return decodeURIComponent(value);
        }
    }
    return null;
}

const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

const API_URL = ENVIRONMENT.API_URL;

export {cookieValue, deleteCookie, API_URL}