import styled from "styled-components";

const TableListWrapper = styled.table `

    width:calc(100% - 100px);
    margin:20px 50px;
    border-collapse:collapse;

`

const TableList = (props) => {

    return (

    <TableListWrapper>
        {props.children}
    </TableListWrapper>
    
    );

}

export default TableList;