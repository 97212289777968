import styled from "styled-components";

const ImageInput = (props) => {

    return (

    <tr>
        <td>
            { props.label ? <label>{props.label}</label> : null}
        </td>
        <td>
            <input type="file" onChange={ props.onChange ? props.onChange : null }></input>
        </td>
    </tr>

    )

}

export default ImageInput;