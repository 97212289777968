import styled from "styled-components";
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import FormUser from "../../components/Admin/FormUser/FormUser";

const FlexDiv = styled.div `

    display:flex;
    flex-direction:row;
    align-items:start;

    & > img {
        width:200px;
        margin:46px 50px;
    }

`

const UserCreate = () => {

    return (

    <>

        <PageTitle>Création d'un utilisateur</PageTitle>
        
        <FlexDiv>
            <FormUser />
        </FlexDiv>

    </>

    );
}

export default UserCreate;