import styled from "styled-components";

const StyledInput = styled.input `

    height:36px;
    width:300px;
    margin:5px 0;
    border:0;
    background:#ecf0f1;
    padding-left:10px;
    outline:0;
    border-radius:6px;
    transition:all .3s ease;

    &:hover {
        background:#d7dadb;
        transition:all .3s ease;
    }


`

const Input = (props) => {

    return <StyledInput type={props.type ? props.type : "text"} placeholder={props.placeholder ? props.placeholder : ""} onChange={props.onChange ? props.onChange : null} />

}

export default Input;