import { useEffect } from "react";
import { deleteCookie } from "../../utils";
import { useNavigate } from "react-router-dom";

const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        deleteCookie('auth');
        navigate("/sec/login");
    }, []);

    return;

}

export default Logout;