import styled from "styled-components"

const PageTitleWrapper = styled.h2 `
    margin:50px 0 20px 50px;
    font-size:40px; 
`

const PageTitle = (props) => {
    return <PageTitleWrapper>{props.children}</PageTitleWrapper>
}

export default PageTitle;