import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableList from "../../components/Admin/TableList/TableList";
import Row from "../../components/Admin/TableList/Row/Row";
import TableHead from "../../components/Admin/TableList/TableHead/TableHead";
import TableBody from "../../components/Admin/TableList/TableBody/TableBody";
import { Link, useNavigate } from "react-router-dom"
import ErrorMessage from "../../components/Admin/ErrorMessage/ErrorMessage";
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import { API_URL, cookieValue } from "../../utils";

const StyledLink = styled(Link) `

    color:rgba(52, 152, 219,1.0);
    font-weight:bold;
    margin:0 50px;
    text-decoration:none;

`

const MessagesWrapper = styled.div `

    margin:0 50px;

`

const TeamList = () => {

    const navigate = useNavigate();

    const [teams, setTeams] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchAPI = async () => {

            try {

                const callAPI = await fetch(API_URL + "/team/getall", {
                    headers: {
                        'Authorization': `TK ${cookieValue('auth')}`,
                    }
                });
                const dataAPI = await callAPI.json();

                if(dataAPI.teams && dataAPI.teams.length !== 0) setTeams(dataAPI.teams);

            }
            catch(e) {
                console.log(e);
            }


        }

        fetchAPI();

    }, []);

    const handleUpdate = (id) => navigate('/adm/teams/update/' + id);

    const handleDelete = async (id) => {

        /* eslint-disable no-restricted-globals */
        const del = confirm("Confirmez la suppression ?");

        if(del) {
            
            try {

                const callAPI = await fetch(API_URL + "/team/delete", {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `TK ${cookieValue('auth')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        teamid: id
                    })
                });

                if(callAPI.status === 404) throw new Error("L'équipe n'existe pas.");

                navigate('/adm/teams/list');

            }
            catch(e) {
                setError(e.message);
            }

        }
        else setError("Non supprimé");

    }

    return (

    <>

        <PageTitle>Gestion des équipes</PageTitle>
        <MessagesWrapper>
            { error ? <ErrorMessage>{error}</ErrorMessage> : null }
        </MessagesWrapper>
        <StyledLink to="/adm/teams/create">Créer une équipe</StyledLink>
        <TableList>
            <TableHead values={["Nom de l'équipe"]} commands={true} />
            <TableBody>
                { teams.map((team) => 
                    <Row key={team.id} values={[team.name]} onUpdate={() => {handleUpdate(team.id)}} onDelete={() => {handleDelete(team.id)}} />
                ) }
            </TableBody>
        </TableList>

    </>
    
    );
}

export default TeamList;