import styled from "styled-components";

const StyledButton = styled.button `

    background:rgba(241, 196, 15,1.0);
    transition:all .3s ease;
    border:0;
    height:38px;
    padding:0 10px;
    cursor:pointer;

    &:hover {
        transition:all .3s ease;
        background:rgba(241, 196, 15,0.8);
    }

`

const AlignRightTD = styled.td `
    text-align:right;
`

const ButtonField = (props) => {

    return (

        <tr>
            <td></td>
            <AlignRightTD>
                <StyledButton onClick={ props.onClick ? props.onClick : null }>Enregistrer</StyledButton>
            </AlignRightTD>
        </tr>

    )

}

export default ButtonField;