import styled from 'styled-components';
import Title from '../../Title/Title';

const WeekGamesWrapper = styled.div `

    display:flex;
    flex-direction:column;
    margin:20px 0 0 0; 
    
    & iframe {
        display:block;
        width:800px;
        overflow:auto;
        margin:10px 0;
        border-width:0px;
        border-radius:12px;

        @media (max-width:500px) {
            width:100%;
        }

    }

`

const WeekGames = () => {

    return (

    <WeekGamesWrapper>
        <Title centered={true}>Rencontres de la semaine</Title>
        <iframe id="657ade3e21f98b08dbd2dfb9" height="500" src="https://v1.scorenco.com/widget/657ade3e21f98b08dbd2dfb9/"></iframe>
    </WeekGamesWrapper>

    );

}

export default WeekGames;