import { useEffect, useState } from "react";
import styled from "styled-components"
import { API_URL, cookieValue } from "../../../../utils";

const DivListPickerWrapper = styled.div `

    & > div {
        font-size:16px;
        padding:6px;
    }

    & > div.active {
        background:black;
        color:white;    
    }

`

const TeamListPicker = (props) => {

    const [teams, setTeams] = useState([]);

    useEffect(() => {

        const fetchAPI = async () => {

            const callAPI = await fetch(API_URL + "/team/getall", {
                headers: {
                    'Authorization': `TK ${cookieValue('auth')}`,
                }
            });
            const dataAPI = await callAPI.json();

            setTeams(dataAPI.teams);

        }

        fetchAPI();

    }, []);


    return (

    <tr>
        <td>Equipes</td>
        <td>
            <DivListPickerWrapper>
            {teams.map((team) => (
            <div key={team.id} onClick={() => props.handleClick ? props.handleClick(team.id) : null } className={props.teams.some(item => item.id === team.id) ? "active" : ""}>
                {team.name}
            </div>
            ))}
            </DivListPickerWrapper>
        </td>
    </tr>

    )


}

export default TeamListPicker;