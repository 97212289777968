import React, { useEffect, useState } from "react";
import TeamCategory from "../../components/Interface/Teams/TeamCategory/TeamCategory";
import styled from "styled-components";
import { API_URL } from "../../utils";

const TeamsWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    
`

const Teams = () => {

    const [teamCategories, setTeamCategories] = useState([]);

    useEffect(() => {
        const titlepage = document.getElementById("titlepage");
        titlepage.innerText = "Nos équipes";
        const titlehtml = document.querySelector("title");
        titlehtml.innerText = "JSA Volley Ball - Nos équipes";
    });

    useEffect(() => {

        const fetchAPI = async () => {

            const apiCall = await fetch(API_URL + "/teamcategory/getall");
            const apiData = await apiCall.json();

            if(apiCall.status !== 200) {
                throw new Error("Erreur lors de l'appel à l'API : " + apiData.error);
            }

            setTeamCategories(apiData.teamCategories);

        }

        fetchAPI();

    }, []);

    return (

    <TeamsWrapper>
        { teamCategories.map((teamCategory, index) => <TeamCategory key={index} teamCategoryId={teamCategory.id} teamCategoryName={teamCategory.name} />) }
    </TeamsWrapper> 

    );
}

export default Teams;