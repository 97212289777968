import styled from "styled-components";

const StyledSelect = styled.select `

    width:400px;
    height:38px;
    border:0;
    background:#ecf0f1;
    padding-left:8px;
    transition:all .3s ease;
    outline:0;

    &:hover {
        transition:all .3s ease;
        background:rgba(189, 195, 199,1.0);
    }

`

const SelectInput = (props) => {

    return (

    <tr>
        <td>
            { props.label ? <label>{props.label}</label> : null }
        </td>
        <td>
            <StyledSelect onChange={props.onChange ? props.onChange : null} value={ props.value } >
                { props.options ? props.options.map((option) => <option key={option[0]} value={ option[0] }>{option[1]}</option>) : null}
            </StyledSelect>
        </td>
    </tr>
    
    );


}

export default SelectInput;