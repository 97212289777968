import styled from 'styled-components';
import Menu from './Menu/Menu';

const MainHeaderWrapper = styled.div`

    position: relative;
    top: 0;
    left:0;
    width: 100%;
    background: white;
    padding: 0 200px;
    display:flex;
    justify-content:space-between;
    align-items:center;

    & > img {

        width:80px;

        @media (max-width:500px) {
            margin:20px 0;
        }

    }

    @media (max-width:500px) {
        padding:0;
        display:flex;
        flex-direction:column;
        justify-content:center;
    }

`;

const MainHeader = () => {

    return (

    <MainHeaderWrapper>
        <img src="/img/logo-jsa.png" />
        <Menu />
    </MainHeaderWrapper>

    );
    
}

export default MainHeader;