import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const AreaWrapper = styled.div `
    position:relative;
    top:0;
    left:0;
    width:100%;
`

const Area = () => {

    return (

    <AreaWrapper>
        <Outlet />
    </AreaWrapper>
    
    );

}

export default Area;