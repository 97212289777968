import { useState } from "react";
import Form from "../Form/Form";
import TextInput from "../Form/TextInput/TextInput";
import ImageInput from "../Form/ImageInput/ImageInput";
import ButtonField from "../Form/ButtonField/ButtonField";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import TeamListPicker from "../Form/TeamListPicker/TeamListPicker";
import { API_URL, cookieValue } from "../../../utils";

const FormPlayer = (props) => {

    const navigate = useNavigate();

    // Form fields
    const [playerName, setPlayerName] = useState(props.playerName ? props.playerName : "");
    const [playerFirstname, setPlayerFirstname] = useState(props.playerFirstname ? props.playerFirstname : "");
    const [playerNb, setPlayerNb] = useState(props.playerNb ? props.playerNb : "");
    const [playerPoste, setPlayerPoste] = useState(props.playerPoste ? props.playerPoste : "");
    const [img, setImg] = useState(null);
    const [teams, setTeams] = useState(props.playerTeams ? props.playerTeams : []);

    // Errors
    const [error, setError] = useState(null);

    /**
     * @param {*} e 
     */
    const handleChangeName = (e) => {

        if(!e.target.value || !e.target.value.length > 50) setError("Veuillez préciser un nom, et faisant moins de 50 caractères.");
        else setError(null);

        setPlayerName(e.target.value);
    }
    
    /**
     * @param {*} e 
     */
    const handleChangeFirstname = (e) => {

        if(!e.target.value || e.target.value.length > 50) setError("Veuillez préciser un prénom, et faisant moins de 50 caractères.");
        else setError(null);

        setPlayerFirstname(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangeNb = (e) => {

        if(e.target.value && !e.target.value.length > 5) setError("Numéro de maillot trop long.");
        else setError(null);

        setPlayerNb(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangePoste = (e) => {

        if(e.target.value && !e.target.value.length > 50) setError("Poste trop long.");
        else setError(null);

        setPlayerPoste(e.target.value);

    }

    /**
     * @param {*} e 
     */
    const handleChangeImg = (e) => {

        const selectedFile = e.target.files[0];

        const maxSize = 5 * 1024 * 1024;
        if (selectedFile && selectedFile.size > maxSize) setError('La taille du fichier dépasse la limite autorisée.');
        else setError(null);

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (selectedFile && !allowedTypes.includes(selectedFile.type)) setError('Type de fichier non autorisé. Veuillez sélectionner une image.');
        else setError(null);
        
        setImg(selectedFile);

    }

    /**
     * @param {*} e 
     */
    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const formData = new FormData();

            formData.append("name", playerName);
            formData.append("firstname", playerFirstname);
            formData.append("nb", playerNb);
            formData.append("poste", playerPoste);
            formData.append("id", props.playerId ? props.playerId : 0);
            formData.append("img", img);

            let teamIds = "";

            teams.forEach((team) => {
                teamIds = team.id + "-";
            });

            formData.append("teams", teamIds);

            const callAPI = await fetch(API_URL + "/player/update", {
                method: 'POST',
                headers: {
                    'Authorization': `TK ${cookieValue('auth')}`,
                },
                body: formData
            });

            if(callAPI.status === 400) throw new Error("Informations incorrectes.");

            navigate("/adm/players/list");

        }
        catch(e) {
            setError(e.message);
        }

    }

    /**
     * @param {*} teamId 
     */
    const handleSwitchTeams = (teamId) => {

        let updatedTeams = teams.filter((team) => team.id !== teamId);

        if(updatedTeams.length === teams.length) updatedTeams.push({id: teamId});

        setTeams(updatedTeams);

    }

    return (

    <>

        <Form>

            <tr>
                <td></td>
                <td>
                    { error ? <ErrorMessage>{error}</ErrorMessage> : null }
                </td>
            </tr>

            <TextInput label="Prénom du joueur" value={playerFirstname} onChange={handleChangeFirstname} />
            <TextInput label="Nom du joueur" value={playerName}  onChange={handleChangeName} />
            <TextInput label="Numéro du maillot" value={playerNb} onChange={handleChangeNb} />
            <TextInput label="Poste du joueur" value={playerPoste} onChange={handleChangePoste} />
            <ImageInput label="Portrait du joueur" onChange={handleChangeImg} />
            <TeamListPicker teams={teams} handleClick={handleSwitchTeams} />
            <ButtonField onClick={ handleSubmit }>Enregistrer</ButtonField>
            
        </Form>

    </>

    )


}

export default FormPlayer;