import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom"
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import FormSponsor from "../../components/Admin/FormSponsor/FormSponsor";
import { API_URL, cookieValue } from "../../utils";

const FlexDiv = styled.div `

    display:flex;
    flex-direction:row;
    align-items:start;

    & > img {
        width:200px;
        margin:46px 50px;
    }

`

const SponsorUpdate = () => {

    const navigate = useNavigate();

    const {id} = useParams();
    const playerId = parseInt(id ? id : 0);

    const [sponsor, setSponsor] = useState(null);

    useEffect(() => {

        const fetchAPI = async () => {

            try {

                const callAPI = await fetch(API_URL + "/sponsor/get?sponsorid=" + playerId, {
                    headers: {
                        'Authorization': `TK ${cookieValue('auth')}`,
                    }
                });
                const dataAPI = await callAPI.json();

                if(callAPI.status === 404) throw new Error("Le sponsor n'existe pas.");
                
                setSponsor(dataAPI.sponsor);

            }
            catch(e) {
                navigate('/adm/teams/list')
            }

        }

        fetchAPI();

    }, []);

    return (

    sponsor &&

    <>

        <PageTitle>Edition : { sponsor.name }</PageTitle>
        <FlexDiv>
            <FormSponsor sponsorName={sponsor.name} sponsorId={sponsor.id} sponsorWebsite={sponsor.website} />
            <img src={sponsor.img ? process.env.REACT_APP_API_HOST + ":" + process.env.REACT_APP_API_PORT + "/sponsor/getimage?sponsorid=" + sponsor.id : "https://placehold.co/400x400/png"} />
        </FlexDiv>

    </>

    );
}

export default SponsorUpdate;