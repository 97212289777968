import React, { useEffect } from "react";
import styled from "styled-components";

const LegalWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;
    padding:30px 0;

    & > h4 {
        text-transform:uppercase;
        font-size:18px;
    }

`

const Legal = () => {

    useEffect(() => {
        const titlepage = document.getElementById("titlepage");
        titlepage.innerText = "Mentions légales";
        const titlehtml = document.querySelector("title");
        titlehtml.innerText = "JSA Volley Ball - Mentions légales";
    });

    return (

    <LegalWrapper>

        <h4>Editeur du site</h4>
        <p>
            PAUL BARBARIN EI (NC : Digimeo Solutions)<br />
            34 TER Rue Pierre Rambaud 33600 Pessac<br />
            07.86.18.26.56<br />
            hello@digimeo-solutions.fr<br />
        </p>

        <h4>Directeur de publication</h4>
        <p>
            TROUILLET Benjamin<br />
            Président<br />
            jsavolleybordeaux@gmail.com
        </p>

        <h4>Hébergeur du site</h4>
        <p>
            OVHCloud<br />
            2 rue Kellermann, 59100 Roubaix <br />
            Tel : 1007<br />
        </p>

        <h4>Propriété intellectuelle</h4>

        <p>
            Le contenu de ce site est protégé par les lois sur la propriété intellectuelle. Tous droits réservés.
        </p>

        <h4>Collecte de données personnelles</h4>

        <p>Ce site ne collecte pas de données personnelles.</p>

        <h4>Cookies</h4>

        <p>Ce site n'enregistre pas de cookies, excepté cookies d'authentification.</p>

        <h4>Responsabilité</h4>

        <p>JSA Volley Ball décline toute responsabilité quant au contenu des sites externes liés à son site.</p>

        <h4>Contact</h4>

        <p>
            9-11 All. des Peupliers, 33000 Bordeaux<br />
            Tel :  05 57 57 08 48<br />
            jsavolleybordeaux@gmail.com
        </p>

    </LegalWrapper>

    );
    
}

export default Legal;