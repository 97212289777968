import { Link } from 'react-router-dom';
import styled from 'styled-components';


const FooterWrapper = styled.footer `

    position:relative;
    display:flex;
    top:0;
    left:0;
    width:100%;
    background: black;
    justify-content:space-evenly;

    @media (max-width:500px) {
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }

    & > div {
        
        @media (max-width: 500px) {
            text-align:center;
            margin:0 0 20px 0;
        }

    }

    & > div > img {

        width:340px;
        margin:30px 0;

        @media (max-width: 500px) {
            margin:30px 0 0 0;
        }

    }


    & > div > h3 {

        color:white;
        font-size:20px;
        margin: 30px 0 16px 0;

    }

    & > div > div {
        display:flex;
        flex-direction:column;
    }

    & > div > div > a {
        transition:all .3s ease;
        color:grey;
        text-decoration:none;
        font-size:16px;
    }

    & > div > div > a:hover {
        transition:all .3s ease;
        color:white;
    }

`

const Footer = () => {

    return (
    
    <FooterWrapper>


        <div><img src="/img/logo-footer.png" /></div>

        <div>

            <h3>Nous contacter</h3>

            <div>
                <a href="mailto:jsavolleybordeaux@gmail.com">Email</a>
                <a href="https://instagram.com/jsavolley" target="_blank">Instagram</a>
            </div>

        </div>

        <div>

            <h3>Liens utiles</h3>

            <div>
                <Link to="/legal">Mentions légales</Link>
                <a href="https://digimeo-solutions.fr" target="_blank">Conception du site</a>
            </div>

        </div>

    </FooterWrapper>
    
    );
    
}

export default Footer;