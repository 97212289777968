import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageTitle from "../../components/Admin/PageTitle/PageTitle";
import FormPlayer from "../../components/Admin/FormPlayer/FormPlayer";
import { API_URL, cookieValue } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";

const FlexDiv = styled.div `

    display:flex;
    flex-direction:row;
    align-items:start;

    & > img {
        width:200px;
        margin:46px 50px;
    }

`

const PlayerUpdate = () => {

    const navigate = useNavigate();

    const {id} = useParams();
    const playerId = parseInt(id ? id : 0);

    const [player, setPlayer] = useState(null);

    useEffect(() => {

        const fetchAPI = async () => {

            try {

                const callAPI = await fetch(API_URL + "/player/get?playerid=" + playerId, {
                    headers: {
                        'Authorization': `TK ${cookieValue('auth')}`,
                    }
                });
                const dataAPI = await callAPI.json();

                if(callAPI.status === 404) throw new Error("Le joueur n'existe pas.");
                
                setPlayer(dataAPI.player);

            }
            catch(e) {
                navigate('/adm/teams/list')
            }

        }

        fetchAPI();

    }, []);

    return (

    player &&

    <>

        <PageTitle>Edition : { player.firstname + " " + player.name }</PageTitle>
        <FlexDiv>
            <FormPlayer playerTeams={player.teams} playerId={player.id} playerName={player.name} playerFirstname={player.firstname} playerNb={player.nb} playerPoste={player.poste} />
            <img src={player.img ? API_URL + "/player/getimage?playerid=" + player.id : "https://placehold.co/400x600/png"} />
        </FlexDiv>

    </>
    
    );
}

export default PlayerUpdate;